export default {
  refund: {
    payment: '결제',
    refundTuition: '환불 가능한 등록금',
    refundMaterial: '환불 가능한 학습 자료',
    refundMaterialTitle: '환불 가능한 학습 자료',
    refundMaterialTips: '배송되지 않은 학습 자료만 환불 신청이 가능합니다. 궁금한 사항이 있으시면 {x}로 문의해주세요.',
    attachedGood: '첨부 상품',
  },
  preclass: '수업 전 준비',
  preclassTips1: '수업 전 준비를 이해하기 위해 ',
  preclassTips2: '을(를) 다운로드해주세요.',
  safetyTips: '당사의 결제 서비스는 안전하게 인증된 국제 결제 기관에서 제공합니다.',
}
