export default {
  refund: {
    payment: 'Payment ',
    refundTuition: 'Refundable ',
    refundMaterial: 'Refundable learning material(s)',
    refundMaterialTitle: 'Refundable learning material(s)',
    refundMaterialTips: 'Only learning materials that have not been shipped can be applied for a refund. If you have any questions, please contact us at: {x}',
    attachedGood: 'Attached good(s)',
  },
  preclass: 'Pre-class Preparation',
  preclassTips1: 'Please download ',
  preclassTips2: ' to understand the pre-class preparation',
  safetyTips: 'Our payment services are provided by internationally certified and secure payment institutions.',
}
