// 课程详情页面配置
export default {
  exceptiontip: '本課程無法報名，查看我們提供的更多課程。',
  retryBtnText: '查看更多課程',
  categoryName: '課程',
  // 教师
  teacher: '教師',
  // 适用学员
  suitable: '適用學員',
  noticeDescribe: `在這裡找不到你孩子的課程？
  單擊此處選擇一名學生並將其切換到當前學生帳戶`,
  // 课程大纲
  syllabus: '課程大綱及時間表',
  startedTag: '已開課',
  courseDetails: '課程信息',
  courseScheduleBtn: ['返回', '查看所有課程({0}節課)'],
  classTransfer: '转班',
  // 右侧报名卡片
  enrollTip: '我們為您留取一個班級名額！',
  discountPrice: '折扣價',
  free: '免費',
  taxiInclude: '',
  taxiExcluded: '',
  perClass: '節課',
  couponTipTitle: '您有可使用的優惠券，請立即報名享受此次優惠！',
  childList: '孩子',
  childList2: '有可用優惠券，快來使用優惠券報名吧',
  couponTipDes: '本次優惠政策將會分攤到您的每筆分期付款，最終展示價格以您購買的課次數量為準。',
  btnStatus: {
    unavailable: '不可用',
    courseEnded: '課程結束',
    forSale: '待售',
    full: '名額已滿',
    enrolNow: '立即報名',
    enrol: '報名',
    autoPayInProcess: '自動扣款中',
  },
  autoPayInProcessTip: '您的帳戶將在24之內進行自動扣款，一旦完成扣款，您將接收到簡訊提醒。',
  // 弹窗
  highlights: '服務亮點',
  successDialog: {
    title: '加入願望清單',
    desc: '在學習中心查看願望清單詳細信息',
    btnText: '知道了',
  },
  assessmentNotice: {
    tips: '請讓您的孩子參加測試，以便被推薦到合適的課程。',
    btnText: '參加測試',
  },
  signUpCheckInfoDialog: {
    case1: {
      title: '請參加入學評估',
      content: '您的孩子在報名參加本課程之前必須通過入學評估。剩餘{0}次嘗試',
      mainBtnText: '確定',
    },
    case2: {
      unableSignUp: '無法報名',
      ok: '確定',
      msg: '您的孩子沒有通過入學評估。我們建議報名參加的課程為：高級級別。',
    },
    case3: {
      title: '提醒',
      mainBtnText: '確定',
    },
    case1014: {
      title: '自動扣款中',
      content: '此班級正在自動扣款中，您的帳戶將在24小時內完成自動扣款，扣款成功後，您將收到簡訊通知。',
      mainBtnText: '知道了',
    },
    case604: {
      title: '無法註冊',
      content: '抱歉，此課程只對新用戶開放，每個用戶只能註冊一次。 快來瀏覽更多課程吧！',
      mainBtnText: '查看課程',
      subBtnText: '取消',
    },
  },
  redeemCodeEntrance: ['如果您有學而思報名資格碼，請在', '這里兌換'],
  redeemQualificationCode: {
    title: '兌換資格碼',
    placeholder: '請輸入您的資格碼',
    btnText: '使用',
    continue: '繼續報名',
    check: '查看適合的班級',
    errorMsg: '請輸入您的資格碼',
    levelDegreeDesc: [
      '您已兌換資格碼，此碼用於{0} {1}個課程註冊',
      '此入學測試結果適用於{0} {1}個班級註冊',
    ],
    tips: ['說明：', '如果您有報名資格碼，請在此處兌換並進行報名', '如果您有任何問題，請聯繫學而思服務中心'],
    fail: '資格碼無效，如果您有任何疑問，請聯繫您的老師或客服：{0}',
  },
  evaluationStatusTxt: {
    case1: '通過診斷測試找出最適合您孩子的課程',
    case2: '您的孩子必須在註冊本課程之前參加診斷測試',
    case3: '您的孩子已通過診斷測試，您現在可以註冊',
    case4: '您孩子的診斷測試成績表明這門課難度過高',
  },
  classFollow: {
    title: '設定成功',
    content: '当班级有空余名额时您将收到报名提醒邮件，同时此班级将为您添加到收藏夹中。',
    mainBtn: '查看收藏夾',
    subBtn: '知道了',
  },
  classFollowCancel: {
    title: '取消提醒',
    content: '取消此班級提醒後，當有空餘名額時，您將收不到報名通知。',
    mainBtn: '确定',
    subBtn: '取消',
    successTip: '取消成功',
  },
};
