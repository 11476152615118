export default {
  // 订阅列表
  subscriptionList: {
    title: '我的訂閱',
    subscriptionDetail: '訂閱詳情',
    changeAddress: '更改地址',
    productType: '產品類型',
    subscriptionStatusList: [
      {
        value: '',
        label: '全部',
      },
    ],
    noOrders: '此類別下沒有訂單。請瀏覽更多由Think Academy提供的課程。',
    moreCourses: '瀏覽更多課程',
  },
  subscriptionCard: {
    pay: '支付',
    unsuceess: '支付失敗',
    viewOrder: '查看訂單',
    start: '開始時間',
    nextPay: '預計下次付款',
    expire: '到期時間',
    expireDate: '到期日期',
    orderTime: '訂單時間',
    statusText: {
      1: '待支付',
      2: '進行中',
      3: '進行中',
      4: '已取消',
    },
  },
  subscriptionDetail: {
    cancel: '取消訂閱',
    cancelReasons: '取消原因',
    title: '訂閱詳情',
  },
  // 支付失败结果页提示文案
  toBePaidResult: {
    title: '付款重要提示',
    content: '若您已被扣款，但訂單並不是“已支付“狀態，可能是因為我們還未收到銀行的扣款通知，請您稍後查看。若有任何問題，請聯繫我們客服：',
    confirm: '知道了',
  },
  personalInfo: {
    childEdit: '孩子資料',
    phone: '手機號',
    emailAddress: '郵箱地址',
    manage: '管理我的孩子及個人資訊',
    manageChild: '管理我的孩子',
    edit: '編輯資料',
  },
  childFilter: {
    child: '孩子',
    type: '類型',
    kids: '孩子',
    save: '保存',
  },
  address: {
    noAddress: '您尚未添加地址',
  },
  coupon: {
    title: '我的優惠券',
    detail: '優惠券詳情',
    recommendClass: '推薦課程',
    testResult: '了解測試結果',
    on: 'On {feeName}',
    over: 'Over {symbol}{price}',
    off: '%Off',
    noAvailable: '沒有可用的課程適用於所選的優惠券。',
    redeemFailedTips: '優惠代碼不存在，請重新嘗試。',
  },
  courses: {
    title: '課程',
    availableClass: '可用課程',
    noCoursesTip: '此類別下沒有課程。請瀏覽更多由Think Academy提供的課程。',
    moreCourses: '瀏覽更多課程',
  },
  tips: {
    editSuccess: '學生編輯成功',
    copyFiled: '複製失敗',
    copySuccess: '複製成功',
    success: '成功',
    redeemSuccess: '兌換成功',
    addSuccess: '學生添加成功',
    login: '請重新登錄',
    loginTips: '您的登錄會話已過期。請重新登錄以繼續',
    cancel: '取消',
    loginAgain: '重新登錄',
    invalid: '此輸入為必填項',
    removeWish: '已從願望清單中移除',
    loading: '加載中...',
    deleteSuccess: '刪除成功',
    addSuccessful: '添加成功',
    updateSuccess: '更新成功',
    updateFailed: '更新失敗。與先前的地址相同',
    viewCourses: '查看課程',
    emptyTips: '即將推出更多可用課程！請查看Think Academy提供的當前可用課程',
    before: 'Before',
    after: 'After',
  },
  coursesCardTag: {
    new: 'New',
    popular: 'Popular',
    full: 'Full',
    current: 'Current',
    completed: 'Completed',
    refunded: 'Refunded',
    playBack: 'Playback Only',
  },
  getNotifiedConfig: {
    title: '找不到合適的課程給您的孩子嗎？',
    description: '沒關係！在您思考一下的同時，請留下您的郵箱，以便我們為您提供入學支持、11+網絡研討會的鏈接以及更多練習材料。',
    form: {
      grade: '孩子的年級',
      firstName: '孩子的名字',
      lastName: '姓氏',
      email: '您的郵箱',
      platform: '您是如何得知我們的？',
    },
    notice: '我想接收最新資訊，包括最新課程資料、學習資源、試題詳解等。',
    emailError: '請輸入有效的郵箱地址',
    phoneError: '請輸入有效的手機號碼',
    errorNotice: '提交失敗，請稍後重試。',
    submit: '提交',
    success: '提交成功，感謝您對學而思的關註！',
  },
  // Cookies声明
  cookiesStatement: {
    description: 'We use cookies to provide you with better services. By clicking any link on this page, your consent has been given.',
  },
  // cookies policy
  cookiesAccept: {
    title: 'Our Use of Cookies',
    description: `<p>We use Cookies to personalise your user experience on our website.</p>
    <p>For more information please see our <a href="/cookies-policy" target="_blank">Cookie Policy</a></p>`,
    accept: 'Accept',
    reject: 'Reject',
  },
  unpaidCourses: {
    tips1: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">Due to high demand for classes, 
      there is a deadline for switching class. Please select the class you want between: <span style="color: #3e6eff;">{startTime} - {endTime}</span></p>`,
    tips2: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">Due to high demand for classes, 
      there is a deadline for switching class. Please select the class you want after: <span style="color: #3e6eff;">{startTime}</span></p>`,
    tips3: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">Due to high demand for classes, 
      there is a deadline for switching class. Please select the class you want before the deadline: <span style="color: #3e6eff;">{endTime}</span></p>`,
  },
}
