export default {
  refund: {
    payment: '支付',
    refundTuition: '可退',
    refundMaterial: '可退的學習資料',
    refundMaterialTitle: '可退的學習資料',
    refundMaterialTips: '只有未發貨的學習資料支持申請退費，若您有任何問題，請聯繫我們的客服：{x}',
    attachedGood: '附加商品',
  },
  preclass: '課前準備',
  preclassTips1: '請下載',
  preclassTips2: '瞭解課前準備',
  safetyTips: '我們的支付服務由國際認證的安全支付機構提供',
}
