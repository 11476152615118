<template>
  <div id="topHeader" class="header" :class="[{ 'sticky': stickyStatus }, `header-${$store.state.locale}` ]">
    <div v-if="realHeaderType === 'double'" class="topbar">
      <div class="wrapper">
        <div class="left">
          <div class="sub-nav">
            <template v-for="(item, index) in subNavList">
              <div :key="index" class="nav-item">
                <a v-if="item.url" class="nav-item-link hover-color-transition" :target="item.target === '_blank' ? '_blank' : '_self'" :href="item.url">
                  {{ item.name }}
                </a>
                <div v-else class="nav-item-link hover-color-transition">
                  {{ item.name }}
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="right">
          <TimezoneMark class="timezone-mark-wrapper" />
          <div class="topbar-login-info">
            <template v-if="isLogin">
              <div class="logged-in">
                <div class="avatar-wrapper">
                  <img :data-not-lazy="true"
                       v-lazy-load :data-src="`${userInfo.parentAvatar}?im=Resize=(112,112)`" class="avatar" :alt="userInfo.parentNickname"
                  >
                </div>
                <div class="info-text text-overflow-ellipsis">
                  {{ userInfo.parentNickname || $t('common.parent') }}
                </div>
                <div class="dropdown-list-wrapper">
                  <ul class="dropdown-list">
                    <li v-for="(item, index) in userNavigationLinks" :key="index" class="dropdown-item hover-color-transition">
                      <a v-if="item.target && item.target === '_blank'" class="dropdown-item-link hover-color-transition" :href="item.url" target="_blank">{{ item.name }}</a>
                      <nuxt-link v-else-if="item.url != ''" class="dropdown-item-link hover-color-transition" :to="item.url">
                        {{ item.name }}
                      </nuxt-link>
                    </li>
                    <li class="dropdown-item">
                      <div class="dropdown-item-link hover-color-transition" @click="handleLogout()">
                        {{ langContext.signOut }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="not-logged-in" @click="handleLogin">
                <div class="iconfont icon-people2" />
                <div class="info-text">
                  {{ langContext.signIn }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="navigation">
      <div class="wrapper">
        <div class="logo">
          <a :href="siteJumpUrl" :title="$t('common.websiteName')">
            <img :data-not-lazy="true" v-lazy-load :data-src="logoUrl" :alt="$t('common.websiteName')" height="36" width="auto">
          </a>
        </div>
        <div class="right">
          <div v-if="siteShowNav" class="site-nav">
            <template v-for="(item, index) in mainNavList">
              <div :key="index" class="nav-item" :class="{'active': currentRoutePath == item.url}" :title="item.name" @click="handleClickNav(item.name)">
                <a v-if="item.url" class="nav-item-link hover-color-transition" :target="item.target === '_blank' ? '_blank' : '_self'" :href="item.url">
                  {{ item.name }}
                </a>
                <div v-else class="nav-item-link hover-color-transition">
                  {{ item.name }}
                </div>
                <div v-if="item.children && item.children.length" class="dropdown-list-wrapper">
                  <ul class="dropdown-list">
                    <template v-for="(childItem, childIndex) in item.children">
                      <li :key="childIndex" class="dropdown-item">
                        <a v-if="childItem.url" class="dropdown-item-link hover-color-transition" :href="childItem.url" :target="childItem.target === '_blank' ? '_blank' : '_self'" @click.stop="handleClickNav(childItem.name)">
                          {{ childItem.name }}
                        </a>
                        <div v-else class="dropdown-item-link hover-color-transition">
                          {{ childItem.name }}
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </template>
          </div>
          <div v-if="realHeaderType === 'single'" class="nav-login-info">
            <template v-if="isLogin">
              <div class="logged-in">
                <div class="avatar-wrapper">
                  <img :data-not-lazy="true" v-lazy-load :data-src="`${userInfo.parentAvatar}?im=Resize=(112,112)`" class="avatar" :alt="userInfo.parentNickname">
                </div>
                <div class="info-text text-overflow-ellipsis">
                  {{ userInfo.parentNickname || $t('common.parent') }}
                </div>
                <div class="dropdown-list-wrapper">
                  <ul class="dropdown-list">
                    <li v-for="(item, index) in userNavigationLinks" :key="index" class="dropdown-item">
                      <a v-if="item.target && item.target === '_blank'" class="dropdown-item-link hover-color-transition" :href="item.url" target="_blank">{{ item.name }}</a>
                      <nuxt-link v-else-if="item.url != ''" class="dropdown-item-link hover-color-transition" :to="item.url">
                        {{ item.name }}
                      </nuxt-link>
                    </li>
                    <li class="dropdown-item">
                      <div class="dropdown-item-link hover-color-transition" @click="handleLogout()">
                        {{ langContext.signOut }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="not-logged-in" @click="handleLogin">
                <div class="iconfont icon-people2" />
                <div class="info-text">
                  {{ langContext.signIn }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tipsList.length > 0 && isShowTipsBox" class="tips-box">
      <div v-for="(item, index) in tipsList" :key="index">
        <div v-if="item.show" class="tips-an">
          <div class="card">
            <div class="l">
              <img v-lazy-load :data-src="item.avatar">
            </div>
            <div class="r">
              <p class="p1">
                <!-- Mary Mack There is a diagnostic assessment, don't forget to take it! -->
                <!-- {{ $t('page.guidePopup', {name: `${item.firstName} ${item.lastName}` })[item.tag] }} -->
                {{ item.firstName+' '+item.lastName }}, <span @click="goAppointment(item)">click here</span>{{ $t('page.guidePopup')[item.tag] }}
              </p>
              <!-- <p class="p2">
                <span @click="goAppointment(item)">Click here</span> View Appointment History
              </p> -->
            </div>
          </div>
          <div class="close" @click="close(item, index)">
            <img v-lazy-load data-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAZZJREFUaEPtmDFOxTAMhu0IBo7AzImYOvAWblE1c6reggWGN3EiZo7AwFOMgl6l6OmlbWy3VSBd0zj/599xkyIU/mDh+qEC7O1gdaA6IMxALSFhAsXTqwPiFAoD/G8Huq677/v+U5JEaQy2A9baRyJ6Q8Rn59yRA2GtbYjoBRGfnHPvnBgsgCDee39ExFsAOCHiIRfiLP4VAG6I6NsY03AgsgGC5QDwAQB3UcayIGLxUYwvAHjILclsgLBgQsAiCMncayXGAuBCaIsPOtgAuRBriBcDLIVYS7wKwBxEGCei327D3fRT7VVUQnHgVJbP76wiXs2BESQBEXMu6lQ5HzQ1BxZAqItXdyAEbNu2McZc1nwYOnnvD8MwsI4dKVdUHZgQP66vDqEGUPQmTokPJRNSf6Wk1PaD2IEp8WO9J0pLBUIEkPOFzXl3kzbKEcSZMwfDckAiRDJX5Tj9Vy405V4poyNDuZf6EUL6SyTEkcZgbeK5zrDleAXYMtsqbXRvwZfr1xLa25HqQHVAmIFaQsIEiqcX78APaZ1cQCqBEeMAAAAASUVORK5CYII=">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { removeCookies } from 'common/utils'
import { oneApi } from 'config/api'
import bus from 'common/bus'
import TimezoneMark from '~/components/global/FeatureComponents/TimezoneMark/web'
import mixin from './mixin'

export default {
  components: {
    TimezoneMark,
    // 悬浮弹框组件
  },
  mixins: [mixin],
  data() {
    return {
      realHeaderType: 'double',
      tipsList: [],
    }
  },
  computed: {
    // 主导航列表
    mainNavList() {
      return this.navigationLinks.filter((item) => (item.navigation && item.navigation === 1) || !item.navigation)
    },

    // 副导航列表
    subNavList() {
      return this.navigationLinks.filter((item) => item.navigation && item.navigation === 2)
    },
  },
  watch: {
    isLogin(newVal) {
      const date = new Date().getTime()
      window.localStorage.setItem('closeAllTipsTime', date)
      window.localStorage.setItem('closeAllTipsStatus', false)
      if (newVal === true) {
        this.getEvalutaionTips()
      }
    },
  },
  mounted() {
    if (this.isLogin) {
      const date = new Date().getTime()
      const time = window.localStorage.getItem('closeAllTipsTime') || date
      if (date - time > 86400000) {
        window.localStorage.setItem('closeAllTipsTime', date)
        window.localStorage.setItem('closeAllTipsStatus', false)
      }
      const status = JSON.parse(window.localStorage.getItem('closeAllTipsStatus')) || false

      if (!status) {
        this.getEvalutaionTips()
      }
    }
    this.querySiteHeaderType()
  },
  methods: {
    handleLogin() {
      bus.$emit('login', {
        triggerSource: 'header',
      })
    },
    async handleLogout() {
      await this.$axios.post(oneApi.commonLogout, {}, { rewritePostBody: true })
      removeCookies('_official_token')
      this.$store.dispatch('ucenter/resetUserInfo')
      this.$store.commit('ucenter/SET_UNPAID_LIST', {})
      this.$store.commit('ucenter/SET_IS_LOGIN', false)
      this.$router.push(this.siteJumpUrl)
    },
    async querySiteHeaderType() {
      const headerSite = window.sessionStorage.getItem('header_site') || ''
      const typeList = ['double', 'single']
      this.realHeaderType = typeList.includes(headerSite) ? headerSite : this.headerType
    },
  },
}
</script>

<style lang="scss" scoped>
/* stylelint-disable */
.header {
  background: #fff;

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}

// 头部条
.topbar {
  height: 40px;
  background-color: var(--txt-color-lv1);

  .wrapper {
    position: relative;
    z-index: 99;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
  }

  .right {
    display: flex;
    align-items: center;
  }

  .sub-nav {
    display: flex;
    justify-content: center;

    .nav-item {
      margin-right: 20px;
      font-size: 12px;
      color: #fff;

      &:last-child {
        margin-right: 0;
      }
    }

    a.nav-item-link {
      color: #fff;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

// 通用下拉列表
.dropdown-list-wrapper {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%)  scaleY(0);
  opacity: 0;
  z-index: 99;
  transition: opacity 0.2s ease-out;

  .dropdown-list {
    position: relative;
    padding: 5px 10px;
    min-width: 120px;
    background: #fff;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 99;
    list-style: none;

    .dropdown-item {
      position: relative;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.2px;
      text-align: center;
      white-space: nowrap;

      .dropdown-item-link {
        display: block;
        height: 32px;
        line-height: 32px;
        padding: 0 10px;
        color: var(--txt-color-lv1);
        font-size: 12px;

        &:hover {
          color: var(--txt-color-link);
        }
      }
    }
  }
}

// 导航条登录状态
.nav-login-info {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 25px 0 25px 60px;
  background: #fff;
  border: 1px solid var(--txt-color-link);
  border-radius: 20px;
  color: var(--txt-color-link);
  flex-shrink: 0;

  .icon-people2 {
    font-size: 14px;
  }

  .avatar-wrapper {
    width: 14px;
    height: 14px;

    .avatar {
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 7px;
    }
  }

  .info-text {
    max-width: 120px;
    margin-left: 7px;
    font-size: 12px;
  }

  .logged-in {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: 40px;
    padding: 0 20px;

    &:hover {
      .dropdown-list-wrapper {
        display: block;
        top: 40px;
        padding-top: 10px;
        transform: translateX(-50%);
        opacity: 1;
        transition: opacity 0.2s ease-in;
      }
    }
  }

  .not-logged-in {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 40px;
    padding: 0 20px;
  }

  &:hover {
    background: var(--txt-color-link);
    color: #fff;
  }
}

// 头部条登录状态
.topbar-login-info {
  height: 40px;
  margin-left: 60px;

  .icon-people2 {
    color: #fff;
    font-size: 14px;
  }

  .avatar-wrapper {
    width: 14px;
    height: 14px;

    .avatar {
      display: block;
      width: 14px;
      height: 14px;
      border: 1px solid #fff;
      border-radius: 7px;
    }
  }

  .info-text {
    margin-left: 7px;
    color: #fff;
    font-size: 12px;
  }

  .logged-in {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: 40px;

    &:hover {
      .avatar-wrapper,
      .info-text {
        opacity: 0.6;
      }

      .dropdown-list-wrapper {
        display: block;
        top: 40px;
        transform: translateX(-50%);
        opacity: 1;
        transition: opacity 0.2s ease-in;
      }
    }
  }

  .not-logged-in {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 40px;

    &:hover {
      opacity: 0.6;
    }
  }
}

// 导航区
.navigation {
  height: 90px;
  border-bottom: 1px solid #e6e6e6;
  min-width: 1240px;

  .wrapper {
    display: flex;
    justify-content: space-between;
    height: 90px;
  }

  .right {
    display: flex;
  }
}

// 品牌Logo
.logo {
  margin: 27px 0;

  img {
    display: block;
  }
}

.header-am, .header-ae {
  .nav-login-info {
    border: 1px solid #2CC0B7;
    color: #2CC0B7;

    &:hover {
      background: #2CC0B7;
      color: #fff;
    }
  }

  .dropdown-list-wrapper .dropdown-list .dropdown-item .dropdown-item-link {
    &:hover {
      color: #2CC0B7;
    }
  }
}
.tips-box{
    width: 343px;
    // height: 113px;
    position: absolute;
    right: 40px;
    top: 40px;
  .tips-an {
    width: 343px;
    background: rgba(34,34,34,0.8);
    border-radius: 8px;
    border: 1px solid rgba(255,255,255,0.3);
    backdrop-filter: blur(10px);
    padding: 16px;
    animation: shang .4s ease-in forwards;
    animation-delay: .5s;
    transform: translateY(50px);
    opacity: 0;
    // height: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    .card {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .l {
        width: 36px;
        margin-right: 8px;
        img{
          width: 100%;
        }
      }
      .r {
        flex: 1;
        .p1 {
          width: 230px;
          font-size: 14px;
          font-family: Montserrat-SemiBold, Montserrat;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 18px;
          span {
            text-decoration: underline;
            cursor: pointer;
            color: #FFA827;
          }
        }
        .p2 {
          font-size: 12px;
          font-family: Montserrat-Medium, Montserrat;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 15px;
          margin-top: 12px;
          span {
            text-decoration: underline;
            cursor: pointer;
            color: #FFA827;
          }
        }
      }
    }
    .close {
      position: absolute;
      cursor: pointer;
      width: 30px;
      height: 30px;
      right: 10px;
      top: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@keyframes shang {
  0%{
    transform: translateY(50px);
    opacity: 0;
    height: 0;
  }
  1%{
    transform: translateY(50px);
    opacity: 0;
    // height: 113px;
  }
  100%{
    transform: translateY(0px);
    opacity: 1;
    // height: 113px;
  }
}
</style>
