export default {
  // 订阅列表
  subscriptionList: {
    title: 'My Subscription',
    subscriptionDetail: 'Subscription Detail',
    changeAddress: 'Change Address',
    productType: 'Product Type',
    subscriptionStatusList: [
      {
        value: '',
        label: 'All',
      },
    ],
    noOrders: 'No orders under this category. Browse more courses provided by Think Academy.',
    moreCourses: 'Browse More Courses',
  },
  subscriptionCard: {
    pay: 'Pay',
    unsuceess: 'Unsuceessful Deduction',
    viewOrder: 'View Order',
    start: 'Start Time',
    nextPay: 'Estimated Next Payment',
    expire: 'Expiration Time',
    expireDate: 'Expiry Date',
    orderTime: 'Order Time',
    statusText: {
      1: 'To be paid',
      2: 'Active',
      3: 'Active',
      4: 'Cancelled',
    },
  },
  subscriptionDetail: {
    cancel: 'Cancel subscription',
    cancelReasons: 'Cancel Reasons',
    title: 'Subscription Detail',
  },
  // 支付失败结果页提示文案
  toBePaidResult: {
    title: '付款重要提示',
    content: '如果您已经被扣款，但订单并不是“已支付”状态，可能是因为我们还未收到银行的扣款通知，请您稍后查看。如果有任何问题，请联系我们客服：',
    confirm: '知道了',
  },
  childFilter: {
    child: 'Child',
    type: 'Type',
    kids: 'Kids',
    save: 'Save',
  },
  address: {
    noAddress: 'You haven\'t added an address yet',
  },
  coupon: {
    title: 'My Coupons',
    detail: 'Coupon Details',
    recommendClass: 'Recommended Class',
    testResult: 'Understanding the test result',
    on: 'On {feeName}',
    over: 'Over {symbol}{price}',
    off: '%Off',
    noAvailable: 'No available course can be applied to the chosen coupon.',
    redeemFailedTips: 'Promo code not found. Please try again',
  },
  courses: {
    title: 'Courses',
    availableClass: 'Available Classes',
    noCoursesTip: 'No courses under this category. Browse more courses provided by Think Academy.',
    moreCourses: 'Browse More Courses',
  },
  tips: {
    editSuccess: 'Student edited successfully',
    copyFiled: 'Copied Failed',
    copySuccess: 'Copied Successfully',
    success: 'Successfully',
    redeemSuccess: 'Redeem Successful',
    addSuccess: 'Student added successfully',
    login: 'Please Login again',
    loginTips: 'Your login session has expired. Please login again to continue',
    cancel: 'Cancel',
    loginAgain: 'Login Again',
    invalid: 'The input is required',
    removeWish: 'Removed from the wish list',
    loading: 'Loading...',
    deleteSuccess: 'Delete Successful',
    addSuccessful: 'Add Successful',
    updateSuccess: 'Update Successful',
    updateFailed: 'Update failed. Same with the previous address.',
    viewCourses: 'View Courses',
    emptyTips: 'Available courses coming soon! View currently available courses provided by Think Academy',
    before: 'Before',
    after: 'After',
  },
  coursesCardTag: {
    new: 'New',
    popular: 'Popular',
    full: 'Full',
    current: 'Current',
    completed: 'Completed',
    refunded: 'Refunded',
    playBack: 'Playback Only',
  },
  getNotifiedConfig: {
    title: 'Can\'t Find an Appropriate Course for Your Child?',
    description: 'That\'s ok! While you take some time to think, leave your email with us to receive admissions support for grammar and independent school entry, links to 11+ webinars and more practice materials.',
    form: {
      grade: 'Child\'s Year group',
      firstName: 'Child\'s first name',
      lastName: 'Last name',
      email: 'Your email',
      platform: 'How did you find out about us?',
    },
    notice: 'Sign up for our mailing list to receive the latest news, promotions and updates! ',
    errorNotice: 'Submission failed. Please try again later.',
    emailError: 'The Email address seems invalid.',
    phoneError: 'The phone number seems invalid.',
    submit: 'Submit',
    success: 'Thank you for your interest in Think Academy!',
  },
  // Cookies声明
  cookiesStatement: {
    description: 'We use cookies to provide you with better services. By clicking any link on this page, your consent has been given.',
  },
  // cookies policy
  cookiesAccept: {
    title: 'Our Use of Cookies',
    description: `<p>We use Cookies to personalise your user experience on our website.</p>
    <p>For more information please see our <a href="/cookies-policy" target="_blank">Cookie Policy</a></p>`,
    accept: 'Accept',
    reject: 'Reject',
  },
  unpaidCourses: {
    tips1: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">Due to high demand for classes, 
      there is a deadline for switching class. Please select the class you want between: <span style="color: #3e6eff;">{startTime} - {endTime}</span></p>`,
    tips2: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">Due to high demand for classes, 
      there is a deadline for switching class. Please select the class you want after: <span style="color: #3e6eff;">{startTime}</span></p>`,
    tips3: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">Due to high demand for classes, 
      there is a deadline for switching class. Please select the class you want before the deadline: <span style="color: #3e6eff;">{endTime}</span></p>`,
  },
}
