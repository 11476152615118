/* eslint-disable max-len */
export default {
  // 订阅列表
  subscriptionList: {
    title: 'Mon Abonnement',
    subscriptionDetail: 'Détail de l\'abonnement',
    changeAddress: 'Changement d\'adresse',
    productType: 'Type de produit',
    subscriptionStatusList: [
      {
        value: '',
        label: 'Tous',
      },
    ],
    noOrders: 'Aucune commande dans cette catégorie. Parcourir d\'autres cours dispensés par Think Academy.',
    moreCourses: 'Parcourir plus de cours',
  },
  subscriptionCard: {
    pay: 'Payer',
    unsuceess: 'Unsuceessful Deduction',
    viewOrder: 'Voir la commande',
    start: 'Heure de début',
    nextPay: 'Prochain paiement estimé',
    expire: 'Heure d\'expiration',
    expireDate: 'Date d\'expiration',
    orderTime: 'Heure de la commande',
    statusText: {
      1: 'À payer',
      2: 'Actif',
      3: 'Actif',
      4: 'Annulé',
    },
  },
  subscriptionDetail: {
    cancel: 'Annuler l\'abonnement',
    cancelReasons: 'Raisons de l\'annulation',
    title: 'Détail de l\'abonnement',
  },
  // 支付失败结果页提示文案
  toBePaidResult: {
    title: 'Rappel de paiement important',
    content: 'Si vous avez été débité, mais la commande n\'est pas dans le statut \'payé, cela peut être dû au fait que nous n\'avons pas encore reçu la notification de déduction de la banque. Veuillez vérifier plus tard. Si vous avez des questions, veuillez nous contacter à:',
    confirm: 'J\'ai compris!',
  },
  personalInfo: {
    childEdit: 'Profil de l\'enfant',
    phone: 'Numéro',
    emailAddress: 'E-mail',
    manage: 'Gérer les informations de mes enfants et mes informations personnelles',
    manageChild: 'Gérer mes enfants',
    edit: 'Modifier mon profil',
  },
  childFilter: {
    child: 'Enfant',
    type: 'Type',
    kids: 'Enfants',
    save: 'Enregistrer',
  },
  address: {
    noAddress: 'Vous n\'avez pas encore ajouté d\'adresse',
  },
  coupon: {
    title: 'Mes coupons',
    detail: 'Détails des coupons',
    recommendClass: 'Cours recommandés',
    testResult: 'Comprendre le résultat du test',
    on: 'en {feeName}',
    over: 'plus {symbol}{price}',
    off: '%Off',
    noAvailable: 'Aucun cours disponible ne peut être appliqué au coupon choisi.',
    redeemFailedTips: 'Le code de réduction n\'existe pas, veuillez réessayer.',
  },
  courses: {
    title: 'cours',
    availableClass: 'Cours disponibles',
    noCoursesTip: 'Aucun cours dans cette catégorie. Parcourez plus de cours proposés par Think Academy.',
    moreCourses: 'Parcourir plus de cours',
  },
  tips: {
    editSuccess: 'Élève modifié avec succès',
    copyFiled: 'Copie échouée',
    copySuccess: 'Copie Succès',
    success: 'Succès',
    redeemSuccess: 'Échange réussi',
    addSuccess: 'Élève ajouté avec succès',
    login: 'Veuillez vous reconnecter',
    loginTips: 'Votre session de connexion a expiré. Veuillez vous reconnecter pour continuer.',
    cancel: 'Annuler',
    loginAgain: 'Se connecter à nouveau',
    invalid: 'Cette saisie est requise',
    removeWish: 'Retiré de la liste de souhaits',
    loading: 'Chargement...',
    deleteSuccess: 'Suppression réussie',
    addSuccessful: 'Ajout réussi',
    updateSuccess: 'Mise à jour réussie',
    updateFailed: 'Échec de la mise à jour. Identique à l\'adresse précédente.',
    viewCourses: 'Voir cours',
    emptyTips: 'Les cours disponibles arrivent bientôt ! Consultez les cours actuellement disponibles proposés par Think Academy',
    before: 'Avant',
    after: 'Après',
  },
  coursesCardTag: {
    new: 'Nouveau',
    popular: 'Populaire',
    full: 'Complet',
    current: 'Actuel',
    completed: 'Achevé',
    refunded: 'Remboursé',
    playBack: 'Playlack uniquement',
  },
  getNotifiedConfig: {
    title: 'Vous ne trouvez pas de cours approprié pour votre enfant?',
    description: 'Ce n\'est pas grave! Pendant que vous prenez le temps de réfléchir, laissez-nous votre adresse e-mail pour recevoir un soutien aux admissions pour la grammaire et l\'entrée dans les écoles indépendantes, des liens vers des webinaires 11+ et plus de matériel de pratique.',
    form: {
      grade: 'Niveau scolaire de l\'enfant',
      firstName: 'Prénom de l\'enfant',
      lastName: 'Nom de famille',
      email: 'Votre adresse e-mail',
      platform: 'Comment avez-vous entendu parler de nous?',
    },
    notice: 'Inscrivez-vous à notre liste de diffusion pour recevoir les dernières nouvelles, promotions et mises à jour! ',
    errorNotice: 'Échec de la soumission. Veuillez réessayer ultérieurement.',
    emailError: 'L\'adresse e-mail semble invalide.',
    phoneError: 'Le numéro de téléphone semble invalide.',
    submit: 'Soumettre',
    success: 'Merci de votre intérêt pour Think Academy!',
  },
  // Cookies声明
  cookiesStatement: {
    description: 'Nous utilisons des cookies pour vous fournir de meilleurs services. En cliquant sur n\'importe quel lien de cette page, vous donnez votre consentement.',
  },
  // cookies policy
  cookiesAccept: {
    title: 'Notre utilisation des cookies',
    description: `<p>Nous utilisons des cookies pour personnaliser votre expérience utilisateur sur notre site web.</p>
    <p>Pour plus d'informations, veuillez consulter notre <a href="/cookies-policy" target="_blank">Politique en matière de cookies</a></p>`,
    accept: 'Accepter',
    reject: 'Rejeter',
  },
  unpaidCourses: {
    tips1: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">En raison de la forte demande pour les cours, il y a une date limite pour changer de classe.
       Sélectionnez le cours que vous souhaitez entre :  <span style="color: #3e6eff;">{startTime} - {endTime}</span></p>`,
    tips2: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">En raison de la forte demande pour les cours, il y a une date limite pour changer de classe. 
      Sélectionnez le cours que vous souhaitez après :   <span style="color: #3e6eff;">{startTime}</span></p>`,
    tips3: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">En raison de la forte demande pour les cours, il y a une date limite pour changer de classe.
       Sélectionnez le cours que vous souhaitez avant la date limite : <span style="color: #3e6eff;">{endTime}</span></p>`,
  },
}
