export default {
  refund: {
    payment: '',
    refundTuition: 'Remboursable ',
    refundMaterial: 'Matériel d\'apprentissage remboursable',
    refundMaterialTitle: 'Matériel d\'apprentissage remboursable',
    refundMaterialTips: 'Seul le matériel pédagogique qui n\'a pas été expédié peut faire l\'objet d\'un remboursement. Si vous avez des questions, veuillez nous contacter à l\'adresse suivante: {x}',
    attachedGood: 'Pièces jointes',
  },
  preclass: 'Préparation avant le cours',
  preclassTips1: 'Veuillez télécharger ',
  preclassTips2: ' de comprendre la préparation avant la classe',
  safetyTips: 'Nos services de paiement sont fournis par des institutions de paiement internationales certifiées et sécurisées.',
}
