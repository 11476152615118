export default {
  refund: {
    payment: '支払い',
    refundTuition: '払い戻し可能',
    refundMaterial: '払い戻し可能な学習資料',
    refundMaterialTitle: '払い戻し可能な学習資料',
    refundMaterialTips: '返金は未発送の教材に限ります。ご不明な点がございましたら、{x}までお問い合わせください',
    attachedGood: '添付されたグッズ',
  },
  preclass: '授業前の準備',
  preclassTips1: '',
  preclassTips2: 'をダウンロードして、授業前の準備事項を見る',
  safetyTips: '本決済サービスは、セキュリティ認証を受けた国際決済機関によって提供されております',
}
